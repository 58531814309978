<!--
* @FileDescription: 管控处置结果
* @Date: 2020年11月19日16:53:27
* @Author: DY
-->
<template>
    <div class="controlDisposal">
        <div class="content_box">
            <table>
                <tbody>
                    <tr>
                        <th>处置人</th>
                        <td>{{ disposalResults.disposal_user_name }}</td>
                        <th>处置时间</th>
                        <td>{{ disposalResults.disposal_time }}</td>
                    </tr>
                    <tr>
                        <th>管理处置意见</th>
                        <td colspan="3">
                            {{ disposalResults.disposal_description }}
                        </td>
                    </tr>
                    <tr>
                        <th>审批结果查看</th>
                        <td colspan="3">
                            <p
                                v-if="disposalResults.transfinite_business_instance_id"
                                @click="see(disposalResults.transfinite_business_instance_id)"
                            >
                                查看审批结果
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <footer>
            <button @click="Close">
                关闭
            </button>
        </footer>
    </div>
</template>

<script>

export default {
    // 管控处置结果
    name: 'control-disposal',
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            // 管控处置结果
            disposalResults: {
                // 处置人
                disposal_user_name: '',
                // 处置时间
                disposal_time: '',
                // 管理处置意见
                disposal_description: '',
            },
        };
    },
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        // 拉取管控处置结果
        getDisposalResults() {
            this.$axios
                .get(`/interfaceApi/sale/control/control_inform/dispose/${this.extr.code}`)
                .then(res => {
                    if (res) {
                        this.disposalResults = res;
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 审批结果查看
        see(id) {
            this.$WFShowSceneLayer(id, 'view');
        },
        // 关闭弹出层
        Close() {
            this.$parent.hide();
        },
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        // 拉取管控处置结果
        this.getDisposalResults();
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
};
</script>
<style lang='stylus' scoped>
.controlDisposal
    padding 0.15rem
    height 100%
    .content_box
        height calc(100% - 0.5rem)
        overflow-y auto
        table
            border-collapse collapse
            width 100%
            th, td
                border 1px solid rgba(215, 215, 215, 0.8)
                font-size 0.16rem
                line-height 0.36rem
                font-weight 400
                text-indent 0.2rem
            th
                width 1.5rem
                background #edf0f5
                color #022782
                text-align left
            td
                p
                    color blue
                    text-decoration underline
                    cursor pointer
    footer
        text-align center
        width 100%
        margin-bottom 0.2rem
        button
            border 2px solid #1577D2
            background #fff
            width 1.84rem
            height 0.44rem
            font-size 0.2rem
            color #1577D2
            cursor pointer
            margin 0 0.2rem
            &:nth-of-type(2)
                background #1577D2
                color #fff
</style>